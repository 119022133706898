<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline"
        v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" @change="changeTime"
            value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="仓库">
          <el-select v-model="searchForm.warehouseId" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'searchForm')" @goodsSelectEvent="goodsDataEvent($event,'searchForm')"></goods-change>
        </el-form-item>
        <el-form-item label="业务类型">
          <el-select v-model="searchForm.type" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in receiveType" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出入库类型">
          <el-select v-model="searchForm.returnType" clearable filterable style="width: 150px" placeholder="可搜索选项">
            <el-option v-for="(item, index) in returnTypeData" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">产品收发明细表</p>
        <template>
          <div class="tableTopBtn">
              <el-button size="mini" type="primary" @click="exportData" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出</el-button>
          </div>
          <el-table ref="multipleTable" height="45vh" :data="tableData" show-summary :summary-method="getSummaries"
            tooltip-effect="dark" style="width: 100%;" v-loading="loading">
            <el-table-column prop="orderCode" label="订单编码" min-width="180" align="center" fixed="left">
              <template slot-scope="scope">
                <div style="color:#409EFF;cursor: pointer;" @click="routeLink(scope.row)">{{ scope.row.orderCode }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" label="产品名称" width="150" align="center" fixed="left">
            </el-table-column>
            <!-- <el-table-column prop="category" label="产品类别" align="center">
            </el-table-column> -->
            <el-table-column prop="standard" label="规格" align="center">
            </el-table-column>
            <el-table-column label="日期" align="center" width="120">
              <template slot-scope="scope">
                {{scope.row.created.split(" ")[0]}}
              </template>
            </el-table-column>
            <el-table-column prop="form" label="业务类型" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.form ==1?'入库': scope.row.form == 2?'出库': scope.row.form == 3?'期初':'' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="returnType" label="出入库类型" align="center" width="100">
            </el-table-column>
            <el-table-column prop="type" label="关联订单类型" align="center" width="120">
              <template slot-scope="scope">
                <p>{{ scope.row.type == 1 ? '采购单' :'销售单' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="warehouseName" label="所属仓库" align="center" width="100">
            </el-table-column>
            <el-table-column label="入库" align="center">
              <el-table-column prop="goodsNum1" label="单位数量" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 1 ? scope.row.goodsNum :''}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="price1" label="单位成本" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 1 ? scope.row.price :0}} 元</p>
                </template>
              </el-table-column>
              <el-table-column prop="cost1" label="成本小计" align="center"  min-width="120px">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 1 ? scope.row.cost :0}} 元</p>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="出库" align="center">
              <el-table-column prop="goodsNum2" label="单位数量" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 2 ? scope.row.goodsNum :''}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="price2" label="单位成本" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 2 ? scope.row.price :0}} 元</p>
                </template>
              </el-table-column>
              <el-table-column prop="cost2" label="成本小计" align="center" min-width="120px">
                <template slot-scope="scope">
                  <p>{{ scope.row.form == 2 ? scope.row.cost :0}} 元</p>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="结余" align="center">
              <el-table-column prop="balance" label="单位数量" align="center">
              </el-table-column>
              <el-table-column prop="price" label="单位成本" align="center">
                <template slot-scope="scope">{{ scope.row.price }} 元</template>
              </el-table-column>
              <el-table-column prop="balanceCost" label="成本小计" align="center"  min-width="120px">
                <template slot-scope="scope">{{ scope.row.balanceCost }} 元</template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination> -->
          </div>
        </template>
      </div>

    </div>
    <!-- 导出 -->
    <el-dialog title="库存收发明细表导出" :visible.sync="exportShow" width="30%" @close="exportPrintTotalShow = false;">
        <el-form :model="exportForm" ref="exportReceiveDetailed" label-width="120px">
          <el-form-item label="选择产品">
            <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'exportForm')" @goodsSelectEvent="goodsDataEvent($event, 'exportForm')" style="width:300px"></goods-change>
          </el-form-item>
          <el-form-item label="选择仓库">
            <el-select v-model="exportForm.warehouseId" clearable filterable style="width: 300px">
              <el-option v-for="(item, index) in warehouseData" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="exportForm.type" clearable filterable style="width: 300px">
                <el-option v-for="(item, index) in receiveType" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="请选择日期">
            <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" @change="changeTime1"
              value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          
          <!-- <el-form-item label="开始日期">
            <el-date-picker
              v-model="exportForm.startTime"
              type="date"
              style="width: 300px"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="00:00:00"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-date-picker
              v-model="exportForm.endTime"
              type="date"
              style="width: 300px"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item> -->
        </el-form>
        <div style="text-align:center;padding-top:20px">
          <el-button type="primary" @click="exportSubmit">导出</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import goodsChange from "../../components/goodsChange.vue";
export default {
  data() {
    return {
      time: [],
      loading: true,
      //搜索
      searchForm: {
        startTime: "",
        endTime: "",
        goodsId:'',
        warehouseId:'',
        type:'',
        returnType: '',
      },
      tableData: [],
      warehouseData: [],
      returnTypeData: [],
      //分页数据
      pagesData: {
        pages:0,
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      searchButton: '',
      exportButton:'',
      adcode: sessionStorage.getItem('adcode'),
      driverList: [],

      // 业务类型
      receiveType:[
        {
          name:'入库',
          value:1
        }, {
          name: '出库',
          value: 2
        }, {
          name: '期初',
          value: 9
        },
      ],
      exportShow:false,
      exportForm:{},

      // 入库、出库、结余合计
      chukucost:'',
      chukunum:'',
      jieyucost:'',
      jieyunum:'',
      rukucost:'',
      rukunum:'',
      lazyFlag:true,
    }
  },
  components: {
    goodsChange
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'warehouse_query') {
        this.searchButton = '1'
        this.exportButton = '1'
      }
    });
    
    
  },
  mounted() {
    if (this.$route.params.goodsId) {
      let goodsId = this.$route.params.goodsId
      this.$set(this.searchForm, 'goodsId', goodsId)
    }
    if(this.$route.params.goodsName){
      this.$set(this.searchForm, 'goodsName', this.$route.params.goodsName)
    }
    this.getSelectGoods()
    this.getDriverList()
    this.getWarehouse()
    this.listEvent()
    this.getReturnType()

    // 表格懒加载
    let table = this.$refs.multipleTable.$el;
    
    table.addEventListener('scroll', (res) => {
      
      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight >= res.target.scrollHeight - 20) {
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    }, true)
  },
  methods: {
    // 订单编码跳转对应详情页面
    routeLink(row){
      console.log(row)
      if(row.orderType == 1){ //采购入库单
        this.$router.push({ name: 'purchaseForm', params: { type: 'details', id: row.orderCode } })
      }else if(row.orderType == 2){ //采购退货单
        this.$router.push({ name: 'purchaseReturnForm', params: { type: 'details', id: row.orderCode } })
      } else if (row.orderType == 3) { //销售出库单
        this.$router.push({ name: 'salesDelivery', params: { orderCode: row.orderCode } })
      } else if (row.orderType == 4) { //销售退货单
        this.$router.push({ name: 'salesReturn', params: { orderCode: row.orderCode } })
      } else if (row.orderType == 5) { //其他入库单
        this.$router.push({ path: '/warehouse_stock', query: { type: 1, orderCode: row.orderCode } })
      } else if (row.orderType == 6) { //其他出库单
        this.$router.push({ path: '/warehouse_stock', query: { type:2,orderCode: row.orderCode } })
      }
    },
    renderHeader(column, index) {
      console.log(column, index, "column, index")
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.driverList = res.data.data;
          }
        });
    },
    // 搜索产品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesReturn", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property == "cost1") {
          sums[index] = this.rukucost +' 元';
        }
        if (column.property == "goodsNum1") {
          sums[index] = this.rukunum;
        }
        if (column.property == "cost2") {
          sums[index] = this.chukucost + ' 元';
        }
        if (column.property == "goodsNum2") {
          sums[index] = this.chukunum;
        }
        if (column.property == "balance"){
          sums[index] = this.jieyunum;
        }
        if(column.property == "balanceCost"){
          sums[index] = this.jieyucost + ' 元';
        }

      //   if (column.property == "goodsNum1" || column.property == "cost1" || column.property == "goodsNum2" || column.property == "cost2") {
      //     let form = column.property.replace(/[^0-9]/g,'')
      //     let name = column.property.substring(0, column.property.length-1)
      //     const values = [];
      //     data.forEach(item => {
      //       if (item.form == form) {
      //         if (name == 'goodsNum') {
      //           values.push(item.goodsNum)
      //         } else if (name == 'cost') {
      //           values.push(item.cost)
      //         }
      //       } else if (column.property == "balance") {
      //         values.push(item.balance)
      //       } else if (column.property == "balanceCost") {
      //         values.push(item.balanceCost)
      //       }
      //     })
          
      //     if (!values.every(value => isNaN(value))) {
      //       sums[index] = values.reduce((prev, curr) => {
      //         const value = Number(curr);
      //         if (!isNaN(value)) {
      //           return prev + curr;
      //         } else {
      //           return prev;
      //         }
      //       }, 0);
      //     }
      //   }

      });

      return sums;
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
          }
        });
    },
    changeTime(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    changeTime1(e) {
      this.exportForm.startTime = e[0]
      this.exportForm.endTime = e[1]
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      this.$axios.get(_this.$axios.defaults.basePath + '/warehouseGoodsRelStatistics/goodsInOutDetail', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          _this.chukucost = res.data.data.chukucost
          _this.chukunum = res.data.data.chukunum
          _this.rukucost = res.data.data.rukucost
          _this.rukunum = res.data.data.rukunum
          _this.jieyucost = res.data.data.jieyucost
          _this.jieyunum = res.data.data.jieyunum

          if (_this.tableData.length == 0) {
            _this.tableData = res.data.data.dataList.records;
          } else {
            _this.tableData = _this.tableData.concat(res.data.data.dataList.records);
          }
          _this.pagesData.total = res.data.data.dataList.total;
          _this.pagesData.pages = res.data.data.dataList.pages;
          if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
            _this.$message({
              message: '已经是最后一页了',
              type: 'info'
            })
          }
          _this.$nextTick(()=>{
            _this.lazyFlag = true
            _this.$refs.multipleTable.doLayout()
          })

        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseData = res.data.data;
          }
        });
    },
    //GET 仓库
    getReturnType() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouseGoodsRelStatistics/getReturnTypeList", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.returnTypeData = res.data.data;
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent()
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent()
    },
    //搜索操作
    searchSubmit() {
      if (!this.searchForm.goodsId) {
        let goodsName = this.$refs.goodsChange.searchName;
        this.$set(this.searchForm, 'goodsName', goodsName)
      }
      this.$set(this.pagesData, 'currentPage', 1)
      this.tableData = []
      this.lazyFlag = true
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.pagesData.currentPage = 1
      this.pagesData.currentRows = 10
      this.$refs.goodsChange.goodsName = ''
      this.time = []
      this.$set(this.searchForm, 'goodsName', '')
      this.tableData = []
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },
    getPopupGoods(data,formName) {
      this.$set(this[formName], 'goodsId', data.id)
    },
    goodsDataEvent(data,formName) {
      console.log(data)
      let goodsIds = [];
      let names = []
      data.forEach(item=>{
        goodsIds.push(item.id)
        names.push(item.goodsName)
      })
      this.$refs.goodsChange.goodsName = names.join(',')
      this.$set(this[formName], 'goodsId', goodsIds.join(','))
    },
    exportData(){
      this.exportShow = true;
    },
    exportSubmit() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouseGoodsRelStatistics/goodsInOutDetailExport", {
          params: _this.exportForm,
          responseType: 'blob'
        })
        .then((res) => {
          console.log(res)
          var eleLink = document.createElement("a");
          eleLink.download = "库存收发明细表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          _this.exportForm = {};
          // 然后移除
          document.body.removeChild(eleLink);
        })

    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}

.boxMain>>>.el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
</style>